import { useMutation, useQuery } from "@apollo/client";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { PageHeading } from "../../components/atoms/page-heading/page-heading";
import { GoBackButton } from "../../components/molecules/go-back-button/go-back-button";
import { Loader } from "../../components/molecules/loader/loader";
import { Notification } from "../../components/molecules/notification/notification";
import { AddIngredientForm } from "../../components/organisms/add-ingredient-form/add-ingredient-form";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import { VALID_IMAGE_TYPES } from "../../constants";
import {
  DELETE_INGREDIENT_ALLERGIES,
  INSERT_INGREDIENT,
  INSERT_INGREDIENT_ALLERGIES,
  UPDATE_INGREDIENT,
} from "../../graphql/mutations";
import { GET_ALL_ALLERGIES, GET_INGREDIENT_TYPE } from "../../graphql/queries";
import { Enum_Notification } from "../../typescript/enum";
import {
  Types_Allergy,
  Type_IngredientsInitialValue,
  Type_IngredientType,
  Type_Notification,
  Type_SelectData,
} from "../../typescript/types";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { replaceSpacesWithPlus } from "../../utils/replaceSpaceWithPlus";

export const CreateNewIngredient: React.FC = () => {
  const history = useHistory();
  const [allergies, setAllergies] = useState<Type_SelectData[]>([]);
  const [selectedAllergies, setSelectedAllergies] = useState<Type_SelectData[]>([]);
  const [isUpdatePage, setIsUpdatePage] = useState(false);
  const [ingredientId, setIngredientId] = useState(0);
  const [allergiesToDelete, setAllergiesToDelete] = useState<number[]>([]);
  const [imageLabel, setImageLabel] = useState("");
  const [loading, setLoading] = useState(true);
  const [ingredientName, setIngredientName] = useState("");
  const [formValues, setFormValues] = useState<Type_IngredientsInitialValue>({
    ingredientName: "",
    scalingUnit: 0,
  });
  const [imageUrl, setImageUrl] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [selectedType, setselectedType] = useState<Type_SelectData>({
    label: "",
    value: "",
  });
  const [ingredientTypes, setIngredientTypes] = useState<Type_SelectData[]>([]);
  const [notification, setNotification] = useState<Type_Notification>({
    heading: "",
    type: Enum_Notification.neutral,
    subHeading: "",
  });
  const fileUploadRef = useRef<any>();
  useQuery(GET_ALL_ALLERGIES, {
    onCompleted: (data) => {
      const _allergies = data.allergies.map((allergy: Types_Allergy) => {
        return {
          label: allergy.name,
          value: allergy.id,
        };
      });
      setAllergies(_allergies);
      setLoading(false);
    },
  });

  useQuery(GET_INGREDIENT_TYPE, {
    onCompleted: (data) => {
      if (!data) return;

      const _ingredientTypes = data?.ingredient_type?.map((type: Type_IngredientType) => {
        return {
          label: type.name,
          value: type.id,
        };
      });
      setIngredientTypes(_ingredientTypes);
    },
    onError: (error) => {
      if (error) {
        setShowNotification(true);
        setNotification({ heading: "An Error Occured!", type: "error" });
      }
    },
  });

  const [addIngredient] = useMutation(INSERT_INGREDIENT);
  const [updateIngredient] = useMutation(UPDATE_INGREDIENT);
  const [deleteAllergies] = useMutation(DELETE_INGREDIENT_ALLERGIES);
  const [insertAllergies] = useMutation(INSERT_INGREDIENT_ALLERGIES);

  const _addIngredient: () => void = async () => {
    setShowNotification(true);
    setNotification({
      heading: "Saving Ingredient!",
      type: "warning",
    });
    try {
      const capitalizedName = capitalizeFirstLetter(formValues.ingredientName ?? "");
      capitalizedName &&
        (await addIngredient({
          variables: {
            objects: {
              name: capitalizedName,
              scaling_unit: formValues.scalingUnit,
              image_url: imageUrl,
              type_id: parseInt(selectedType.value),
              ingredient_allergies: {
                data: selectedAllergies.map((allergy) => {
                  return {
                    allergies_id: allergy.value,
                  };
                }),
              },
            },
          },
        }));
      setShowNotification(true);
      setNotification({
        heading: "Ingredient saved successfully!",
        type: "success",
      });
      goToIngredientsPage();
    } catch (error) {
      setShowNotification(true);
      setNotification({
        heading: "Unable to save ingredient!",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const _updateIngredients: (ingredientId: number) => void = async (ingredientId) => {
    const capitalizedName = capitalizeFirstLetter(formValues.ingredientName ?? "");
    setShowNotification(true);
    setNotification({
      heading: "Updating Ingredient!",
      type: "warning",
    });
    if (!capitalizedName) return;

    try {
      await updateIngredient({
        variables: {
          where: {
            id: {
              _eq: ingredientId,
            },
          },
          _set: {
            name: capitalizedName,
            scaling_unit: formValues.scalingUnit,
            image_url: imageUrl,
            type_id: parseInt(selectedType.value),
          },
        },
      });

      await deleteAllergies({
        variables: {
          where: {
            allergies_id: {
              _in: allergiesToDelete,
            },
            ingredients_id: {
              _eq: ingredientId,
            },
          },
        },
      });

      await insertAllergies({
        variables: {
          objects: selectedAllergies.map((allergy) => {
            return {
              allergies_id: allergy.value,
              ingredients_id: ingredientId,
            };
          }),
        },
      });

      setShowNotification(true);
      setNotification({
        heading: "Ingredient Updated!",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      setShowNotification(true);
      setNotification({
        heading: "Unable to update ingredient!",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const throwError = (heading: string) => {
    setShowNotification(true);
    setNotification({
      heading,
      type: "error",
    });
    return false;
  };

  const validations = () => {
    if (!formValues.ingredientName) return throwError("Ingredient name is required!");
    else if (!formValues.ingredientName.match(/^[a-zA-Z()\s]*$/))
      return throwError("Ingredient name cannot contain numbers or special characters!");
    // else if (!imageUrl) return throwError("Ingredient image is required!");
    else if (!selectedType.value) return throwError("Select ingredient type");
    else if (formValues.scalingUnit <= 0) return throwError("Valid scaling unit is required");
    // else if (selectedAllergies.length <= 0) return throwError("Enter allergies for the ingredient");
    else return true;

  };

  const handleSubmitIngredient: () => void = () => {
    if (!validations()) return;

    setLoading(true);
    if (!isUpdatePage) {
      _addIngredient();
    } else if (isUpdatePage) {
      _updateIngredients(ingredientId);
    }
  };

  const fileValidation: (file: File) => boolean = (file) => {
    const fileType = file.type.split("/")[1];
    if (file.size > 2000000) {
      setShowNotification(true);
      setNotification({
        heading: "Image is too large!",
        subHeading: "Max image size is 2Mb.",
        type: "error",
      });
      return false;
    } else if (!VALID_IMAGE_TYPES.some((type) => type === fileType)) {
      setShowNotification(true);
      setNotification({
        heading: "Wrong image type",
        subHeading: "Only PNG, JPG and JPEG are allowed.",
        type: "error",
      });
      return false;
    } else return true;
  };

  const handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void = async (e) => {
    setShowNotification(true);
    setNotification({
      heading: "Image is uploading",
      type: Enum_Notification.warning,
    });
    setImageLabel("");
    setImageUrl("");
    try {
      if (e.target.files) {
        const file = e.target.files[0];

        if (!fileValidation(file)) {
          fileUploadRef.current.value = "";
          return;
        }

        let data = new FormData();
        data.append("files", file, `${Date.now()}_${file.name}`);
        const response = await fetch(process.env.REACT_APP_UPLOAD_URL ?? "", {
          method: "POST",
          body: data,
        });
        const _response = await response.json();
        setImageLabel(_response.filesUrl[0].originalname);
        const imageUrl = `${process.env.REACT_APP_BASE_URL_S3_BUCKET_RESPONSE}/${replaceSpacesWithPlus(
          _response.filesUrl[0].originalname,
        )}`;
        setImageUrl(imageUrl);

        isUpdatePage &&
          (await updateIngredient({
            variables: {
              where: {
                id: {
                  _eq: ingredientId,
                },
              },
              _set: {
                image_url: imageUrl,
              },
            },
          }));

        setShowNotification(true);
        setNotification({
          heading: "Image uploaded successfully!",
          type: Enum_Notification.success,
        });
      }
    } catch (error) {
      console.log(error);
      setShowNotification(true);
      setNotification({
        heading: "Image was not uploaded!",
        type: Enum_Notification.error,
      });
      fileUploadRef.current.value = "";
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = async () => {
    fileUploadRef.current.value = "";
    setShowNotification(true);
    setNotification({
      heading: "Removing image!",
      type: Enum_Notification.warning,
    });
    isUpdatePage &&
      (await updateIngredient({
        variables: {
          where: {
            id: {
              _eq: ingredientId,
            },
          },
          _set: {
            image_url: imageUrl,
          },
        },
      }));
    setShowNotification(true);
    setNotification({
      heading: "Image removed!",
      type: Enum_Notification.success,
    });
    setImageUrl("");
    setImageLabel("");
  };

  const goBack = () => history.goBack();

  const setIsUpdatePageToTrue: () => void = () => setIsUpdatePage(true);

  const _setIngredientId: (id: number) => void = (id) => setIngredientId(id);

  const goToIngredientsPage: () => void = () => history.push(`/ingredients`);

  return (
    <PageTemplate>
      <div onClick={() => goBack()}>
        <GoBackButton />
      </div>
      <PageHeading heading={isUpdatePage ? "Update Ingredient" : "Create New Ingredient"} />
      {!loading ? (
        <AddIngredientForm
          {...{
            allergies,
            handleSubmitIngredient,
            selectedAllergies,
            setSelectedAllergies,
            goBack,
            setIsUpdatePageToTrue,
            isUpdatePage,
            _setIngredientId,
            setAllergiesToDelete,
            handleImageUpload,
            imageLabel,
            imageUrl,
            setImageUrl,
            loading,
            setLoading,
            setImageLabel,
            setShowNotification,
            setNotification,
            formValues,
            setFormValues,
            handleRemoveImage,
            selectedType,
            setselectedType,
            ingredientTypes,
            fileUploadRef,
          }}
        />
      ) : (
        <Loader />
      )}
      {showNotification && <Notification {...notification} showNotification={setShowNotification} />}
    </PageTemplate>
  );
};
