import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Types_Ingredients, Type_IngredientType } from "../../../typescript/types";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { Loader } from "../../atoms/loader/loader";
import { SingleIngredientControls } from "../../molecules/single-ingredient-controls/single-ingredient-controls";
type ListProps = {
  data: Types_Ingredients[];
  totalIngredients: number;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  pagination: () => void;
  justSearchedFlag: boolean;
  ingredientTypes: Type_IngredientType[];
};

export const IngredientsList: React.FC<ListProps> = ({
  data,
  totalIngredients,
  handleEdit,
  handleDelete,
  pagination,
  justSearchedFlag,
  ingredientTypes,
}) => {
  console.log({ ingredientTypes });
  return (
    <InfiniteScroll
      dataLength={data.length}
      hasMore={!justSearchedFlag && data.length < totalIngredients}
      loader={<Loader />}
      next={() => pagination()}
    >
      <table className="w-full">
        <thead>
          <tr className="text-gray3 text-left border-b border-[#eae9e9] text-[12px] leading-[18px] ">
            <th className="w-[400px] py-[21px] px-[15px]">Name</th>
            <th className="w-[230px]">Scaling Unit</th>
            <th className="w-[230px]">Allergens</th>
            <th className="w-[230px]">Type</th>
            <th className="w-[230px]"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const allergyNames = item.ingredient_allergies
              .filter((allergies, index) => {
                if (index === 0 || index === 1) return allergies;
              })
              .map((allergies) => capitalizeFirstLetter(allergies.allergy.name))
              .join(", ");
            const allergyString = allergyNames.includes(",") ? `${allergyNames}, ...` : allergyNames;
            return (
              <tr
                className="text-[12px] leading-[18px]  text-gray3 font-normal border-b border-[#eae9e9] hover:bg-[#F8F8F8] cursor-pointer"
                key={item.id}
              >
                <td className="w-[400px] text-primary py-[21px] font-semibold px-[15px] capitalize">{item.name}</td>
                <td className="w-[230px]">{item.scaling_unit && item.scaling_unit}</td>
                <td className="w-[230px] truncate">{allergyString}</td>
                <td className="w-[230px]">{ingredientTypes.find((type) => item.type_id === type.id)?.name}</td>
                <td className="w-[230px]">
                  <SingleIngredientControls {...{ handleEdit, handleDelete }} id={item.id} />{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};
