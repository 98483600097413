import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/molecules/loader/loader";
import { Notification } from "../../components/molecules/notification/notification";
import { ListHeader } from "../../components/organisms/list-header/list-header";
import { MealsList } from "../../components/organisms/meals-list/meals-list";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import { DELETE_MEAL_BY_ID } from "../../graphql/mutations";
import { GET_ALL_MEALS, GET_ALL_MEALS_TOTAL, SEARCH_MEALS } from "../../graphql/queries";
import { Type_Meals, Type_Notification } from "../../typescript/types";

const LIMIT = 30;
const OFFSET = 30;

export const Meals: React.FC = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [meals, setMeals] = useState<Type_Meals[]>([]);
  const [totalMeals, setTotalMeals] = useState(0);
  const [offset, setOffset] = useState(0);
  const [justDeletedFlag, setJustDeletedFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [justSearchedFlag, setJustSearchedFlag] = useState(false);
  const [notification, setNotification] = useState<Type_Notification>({
    heading: "",
    type: "neutral",
    subHeading: "",
  });
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const [getMeals] = useLazyQuery(GET_ALL_MEALS, {
    variables: {
      limit: LIMIT,
      offset: offset,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      // setMeals(data.meal)
      const _meals = meals;
      const concatMeals = _meals.concat(data.meal);
      setMeals(concatMeals);
      setLoading(false);
    },
  });

  const [getTotal] = useLazyQuery(GET_ALL_MEALS_TOTAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTotalMeals(data.meal_aggregate.aggregate.count);
    },
  });

  const [searchForMeals] = useLazyQuery(SEARCH_MEALS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _meals = meals;
      const concatMeals = _meals.concat(data.meal);
      setMeals(concatMeals);
      setLoading(false);
    },
  });

  const [deleteMeal] = useMutation(DELETE_MEAL_BY_ID);

  const handleAddToList = () => history.push(`/create-new-meal`);

  const handleOnChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) =>
    setSearchValue(e.target.value);

  const handleEdit: (id: number) => void = (id) => history.push(`/update-meal/${id}`);

  const pagination = () => {
    // flag so that the function does not update value of
    // offset when an ingredient is deleted.
    // set to True after 2 sec of deletion
    if (!justDeletedFlag) {
      const _offset = offset + OFFSET;
      setOffset(_offset);
      getMeals({
        variables: {
          limit: LIMIT,
          offset: _offset,
        },
      });
    }
  };

  const handleDelete: (id: number) => void = async (id) => {
    try {
      setIsNotificationVisible(true);
      setNotification({
        heading: "Deleting...",
        type: "warning",
      });
      await deleteMeal({
        variables: {
          where: {
            id: {
              _eq: id,
            },
          },
        },
      });
      setJustDeletedFlag(true);
      setMeals([]);
      setOffset(0);
      search()
      // getMeals({
      //   variables: {
      //     limit: LIMIT,
      //     offset: 0,
      //   },
      // });
      // getTotal();
      setIsNotificationVisible(true);
      setNotification({
        heading: "Deleted!",
        type: "success",
      });
      setTimeout(() => {
        setJustDeletedFlag(false);
      }, 2000);
    } catch (error) {
      setIsNotificationVisible(true);
      setNotification({
        heading: "Unable to delete!",
        subHeading: "Meal is part of planned meal.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getTotal();
    getMeals({
      variables: {
        limit: 30,
        offset: 0,
      },
    });
  }, []);

  const search = () => {
    if (!searchValue) return;
    setOffset(0);
    setMeals([]);
    setLoading(true);
    setJustSearchedFlag(true);
    searchForMeals({
      variables: {
        where: {
          name: {
            _ilike: `%${searchValue.trim()}%`,
          },
        },
      },
    });
  };

  const onPressEnterInSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) =>
    e.key === "Enter" && search();

  const onClickEmptySearchButton = () => {
    setMeals([]);
    setJustSearchedFlag(false);
    setLoading(true);
    getTotal();
    getMeals({
      variables: {
        limit: 30,
        offset: 0,
      },
    });
    setSearchValue("");
  };

  const onClickSearchIcon = () => {
    search();
  };

  return (
    <PageTemplate>
      <ListHeader
        {...{
          handleAddToList,
          handleOnChangeSearch,
          searchValue,
          onPressEnterInSearch,
          onClickEmptySearchButton,
          onClickSearchIcon,
        }}
        pageHeading={`Dishes (${totalMeals})`}
      />
      {!loading ? (
        <MealsList {...{ handleDelete, handleEdit, totalMeals, pagination, justSearchedFlag }} data={meals} />
      ) : (
        <Loader />
      )}
      {isNotificationVisible && <Notification {...notification} showNotification={setIsNotificationVisible} />}
    </PageTemplate>
  );
};
