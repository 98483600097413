import React from "react";
import Select from "react-select";
import { TODO, Type_SelectData, Type_SelectedIngredientObject } from "../../../typescript/types";
import { BurgerIcon } from "../../atoms/burger-icon/burger-icon";
import { DeleteRowIcon } from "../../atoms/delete-row-icon/delete-row-icon";
import "./styles.css";

type SelectedIngredientsProps = {
  selectedIngredients: Type_SelectData[];
  onDeleteIngredient: (id: number, index: number) => void;
  selectedIngredientsObject: Type_SelectedIngredientObject[];
  onChangeIngredientsObject: (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    id: number,
    value?: Type_SelectData,
    action?: TODO,
  ) => void;
  units: TODO;
  preparations: TODO;
  ingredientPreparationTime: TODO;
  setIngredientPreparationTime: React.Dispatch<React.SetStateAction<any[]>>;
  ingredientFromUnits: TODO;
  ingredientToUnits: TODO;
  setIngredientToUnits: React.Dispatch<React.SetStateAction<any[]>>;
  setIngredientFromUnits: React.Dispatch<React.SetStateAction<any[]>>;
};
export const SelectedIngredients: React.FC<SelectedIngredientsProps> = ({
  selectedIngredients,
  onDeleteIngredient,
  selectedIngredientsObject,
  onChangeIngredientsObject,
  units,
  preparations,
  ingredientPreparationTime,
  ingredientFromUnits,
  ingredientToUnits,
  setIngredientToUnits,
  setIngredientFromUnits,
  setIngredientPreparationTime,
}) => {
  return (
    <table className="w-[860px] mb-[50px]">
      <thead>
        <tr className="border-b border-[#eae9e9] ">
          <th className="ml-[6px]"></th>
          <th className="w-[200px] flex items-center h-full py-[11px] pl-[7px]">Name</th>
          <th className="w-[200px]">Preparation</th>
          <th className="w-[58px] px-[14px]">Qty*</th>
          <th className="pr-[14px] w-[120px]">Unit*</th>
          <th>Scaling Unit</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {selectedIngredientsObject.map((selectedIngredient, index) => (
          <tr key={selectedIngredient.id}>
            <td className="text-center ml-[6px]">
              <BurgerIcon />
            </td>
            <td className="w-[200px] py-[13px] pl-[7px]">{selectedIngredient.ingredient}</td> {/* name */}
            <td className="w-[200px] ">
              {" "}
              {/* Preparation */}
              <Select
                options={preparations}
                onChange={(value: TODO, action) => {
                  const _ingredientsPreparationTime = ingredientPreparationTime;
                  _ingredientsPreparationTime[index] = value;
                  setIngredientPreparationTime(_ingredientsPreparationTime);
                  onChangeIngredientsObject(undefined, selectedIngredient.id, value, action);
                }}
                value={ingredientPreparationTime[index]}
                name="preparationTime"
              />
            </td>
            <td className="w-[58px] px-[14px]">
              {" "}
              {/* Qty 1 */}
              <input
                placeholder=""
                className="w-[58px] px-[8px] border border-[#b9b9b9] rounded-[4px]"
                onChange={(e) => onChangeIngredientsObject(e, selectedIngredient.id)}
                value={selectedIngredient.fromQuantity}
                type="number"
                style={{ height: 36, marginBottom: 0 }}
                name="fromQuantity"
                min={0}
              />
            </td>
            <td className="pr-[14px] w-[120px]">
              {" "}
              {/* Unit 1 */}
              <Select
                name="unit"
                options={units}
                isSearchable
                value={ingredientFromUnits[index]}
                onChange={(value: TODO, action) => {
                  const _ingredientUnits = ingredientFromUnits;
                  _ingredientUnits[index] = value;
                  setIngredientFromUnits(_ingredientUnits);
                  onChangeIngredientsObject(undefined, selectedIngredient.id, value, action);
                }}
              />
            </td>
            {/* icon */}
            {/* <td className="w-[50px] text-center"> <div className="flex justify-center"><AiOutlineArrowRight className="text-center text-lg" /></div> </td>  */}
            {/* Qty 2 */}
            {/* <td className="w-[58px] px-[14px]"> 
                      <input
                        placeholder=""
                        className="w-[50px] px-[8px] border border-[#b9b9b9] rounded-[4px]"
                        onChange={(e) => {
                          if (parseFloat(e.target.value) > 0) {
                            onChangeIngredientsObject(e, selectedIngredient.id);
                          }
                        }}
                        value={selectedIngredient.toQuantity}
                        type="number"
                        style={{ height: 36, marginBottom: 0 }}
                        name="toQuantity"
                        min={0}
                      />
                    </td> */}
            {/* Unit 2 */}
            {/* <td className="pr-[14px] w-[120px]"> 
                      <Select
                        name="toUnit"
                        options={units}
                        isSearchable
                        value={ingredientToUnits[index]}
                        onChange={(value: TODO, action) => {
                          const _ingredientUnits = ingredientToUnits;
                          _ingredientUnits[index] = value;
                          setIngredientToUnits(_ingredientUnits);
                          onChangeIngredientsObject(undefined, selectedIngredient.id, value, action);
                        }}
                      />
                    </td> */}
            <td>
              {" "}
              {/* Scaling Unit */}
              <input
                placeholder=""
                className="w-[92px] px-[8px] border border-[#b9b9b9] rounded-[4px]"
                onChange={(e) => {
                  if (parseFloat(e.target.value) > 0) {
                    onChangeIngredientsObject(e, selectedIngredient.id);
                  }
                }}
                value={selectedIngredient.scalingUnit}
                type="number"
                style={{ height: 36, marginBottom: 0 }}
                name="scalingUnit"
                min={0}
              />
            </td>
            <td onClick={() => onDeleteIngredient(selectedIngredient.id, index)}>
              <div className="w-full flex justify-center">
                <DeleteRowIcon />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
