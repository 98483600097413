import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Type_Meals } from "../../../typescript/types";
import { Loader } from "../../atoms/loader/loader";
import { SingleIngredientControls } from "../../molecules/single-ingredient-controls/single-ingredient-controls";
type MealsProps = {
  data: Type_Meals[];
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  totalMeals: number;
  pagination: () => void;
  justSearchedFlag: boolean;
};

export const MealsList: React.FC<MealsProps> = ({
  data,
  handleEdit,
  handleDelete,
  totalMeals,
  pagination,
  justSearchedFlag,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      hasMore={!justSearchedFlag && data.length < totalMeals}
      loader={<Loader />}
      next={() => pagination()}
      className="w-full"
    >
      <table className="w-full">
        <thead>
          <tr className="text-gray3 text-left border-b border-[#eae9e9] text-[12px] leading-[18px] ">
            <th className="w-[400px] py-[21px] px-[15px]">Name</th>
            <th className="w-[230px]">Cuisine</th>
            <th className="w-[230px]">Image</th>
            <th className="w-[230px]"></th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.map((item) => {
              return (
                <tr
                  className="text-[12px] leading-[18px]  text-gray3 font-normal border-b border-[#eae9e9] hover:bg-[#F8F8F8] cursor-pointer"
                  key={item.id}
                >
                  <td className="w-[400px] text-primary py-[21px] font-semibold px-[15px] capitalize">{item.name}</td>
                  <td className="w-[230px]">{item.cuisine}</td>
                  {/* <td className="w-[230px]">{item.image_url ? "Y" : "N"}</td> */}
                  <td className="w-[230px] overflow-hidden">
                    {" "}
                    {item.image_url && <img src={item.image_url} alt="" className="w-[50px] h-[50px] rounded-md" />}
                  </td>
                  <td className="w-[230px]">
                    <SingleIngredientControls {...{ handleEdit, handleDelete }} id={item.id} />{" "}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
};
