import React, { useState } from 'react'
import { PageHeading } from '../../components/atoms/page-heading/page-heading'
import { HomeCard } from '../../components/molecules/home-card/home-card'
import { PageTemplate } from '../../components/templates/page-template/page-template'
import IngredientsPNG from "../../assets/images/dashboard-bg-ingredients.png"
import DishesPNG from "../../assets/images/dashboard-bg-dishes.png"
import { useQuery } from '@apollo/client'
import { GET_ALL_INGREDIENTS_TOTAL, GET_ALL_MEALS_TOTAL } from '../../graphql/queries'
import { Loader } from '../../components/molecules/loader/loader'
export const Home: React.FC = () => {
  const [totalMeals, setTotalMeals] = useState(0);
  const [totalIngredients, setTotalIngredients] = useState(0)
  const {loading: allMealsTotal_Loading} = useQuery(GET_ALL_MEALS_TOTAL, {
    onCompleted: (data) => {
      setTotalMeals(data.meal_aggregate.aggregate.count)
    }
  })
  const {loading: allMeals_Loading} = useQuery(GET_ALL_INGREDIENTS_TOTAL, {
    onCompleted: (data) => {
      setTotalIngredients(data.ingredients_aggregate.aggregate.count)
    }
  })

  return (
    <PageTemplate>
      <div className="flex justify-between items-center relative">
        <PageHeading heading={`Welcome back User`} />
        <p className="text-[#ee4e4e] text-[13px] leading-[15px] font-normal text-right cursor-pointer">Log out</p>
      </div>
      {(allMealsTotal_Loading || allMeals_Loading) ? <Loader />  : <div className="flex gap-x-[60px] absolute top-[20%] left-[50%] ml-[-500px]">
        <HomeCard heading="Ingredients" amount={totalIngredients} image={IngredientsPNG} isIngredients viewAll="/ingredients" addNew="/create-new-ingredient" />
        <HomeCard heading="Dishes" amount={totalMeals} image={DishesPNG} viewAll="/meals" addNew="/create-new-meal" />
      </div>}
    </PageTemplate>
  );
}
