import { useLazyQuery, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "../../components/molecules/loader/loader";
import { PageHeading } from "../../components/atoms/page-heading/page-heading";
import { GoBackButton } from "../../components/molecules/go-back-button/go-back-button";
import { AddMealForm } from "../../components/organisms/add-meal-form/add-meal-form";
import { PageTemplate } from "../../components/templates/page-template/page-template";

import {
  GET_ALL_ALLERGIES,
  GET_ALL_CUISINE_TYPES,
  GET_ALL_INGREDIENTS,
  GET_ALL_MEALS,
  GET_GROUP_TAGS,
  GET_MEAL_DETAILS_BY_ID,
  GET_PREPARATIONS,
  GET_TAGS,
  GET_UNITS,
} from "../../graphql/queries";
import {
  Types_Allergy,
  Type_Column,
  Type_IngredientsWithScalingUnit,
  Type_MealInitialValues,
  Type_Notification,
  Type_SelectData,
} from "../../typescript/types";
import { Notification } from "../../components/molecules/notification/notification";
import { Enum_Droppables, Enum_Notification } from "../../typescript/enum";

export const CreateNewMeal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [mealId, setMealId] = useState<null | number>(null);
  const [isUpdatePage, setIsUpdatePage] = useState(false);
  const [cuisineType, setCuisineType] = useState<Type_SelectData[]>([]);
  const [allergies, setAllergies] = useState<Type_SelectData[]>([]);
  const [dishes, setDishes] = useState<Type_SelectData[]>([]);
  const [ingredients, setIngredients] = useState<Type_SelectData[]>([]);
  const [ingredientsWithScaling, setIngredientsWithScaling] = useState<Type_IngredientsWithScalingUnit[]>([]);
  const [units, setUnits] = useState<Type_SelectData[]>([]);
  const [tags, setTags] = useState<Type_SelectData[]>([]);
  const [imageLabel, setImageLabel] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [preparations, setPreparations] = useState<Type_SelectData[]>([]);
  const [selectedIngredientsObject, setSelectedIngredientsObject] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [columns, setColumns] = useState<Type_Column[]>([
    {
      id: Enum_Droppables.Procedure,
      itemIds: [],
    },
    {
      id: Enum_Droppables.Recipe,
      itemIds: [],
    },
  ]);
  const [notification, setNotification] = useState<Type_Notification>({
    heading: "",
    type: Enum_Notification.neutral,
    subHeading: "",
  });
  const [formValues, setFormValues] = useState<Type_MealInitialValues>({
    name: "",
    description: "",
    prepTime: 0,
    servingSize: 0,
    shelfLife: 0,
    videoURL: "",
  });
  const goBack = () => history.goBack();

  useQuery(GET_ALL_CUISINE_TYPES, {
    variables: {
      where: {
        category_tag: {
          slug: {
            _eq: "cuisine_type",
          },
        },
      },
      distinct_on: ["tags"],
    },
    onCompleted: (data) => {
      const _cuisineType = data.filter_tags.map((tag: any) => {
        return { label: tag.tags, value: tag.id };
      });
      setCuisineType(_cuisineType);
    },
  });

  useQuery(GET_ALL_ALLERGIES, {
    onCompleted: (data) => {
      const _allergies = data.allergies.map((allergy: Types_Allergy) => {
        return {
          label: allergy.name,
          value: allergy.id,
        };
      });
      setAllergies(_allergies);
    },
  });

  useQuery(GET_ALL_MEALS, {
    fetchPolicy: "no-cache",
    
    onCompleted: (data) => {
      const _dishes = data.meal.map((dish: any) => {
        return {
          label: dish.name,
          value: dish.id,
        };
      });
      setDishes(_dishes);
    },
  });

  useQuery(GET_ALL_INGREDIENTS, {
    variables: {
      order: { name: "asc" },
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _ingredients = data.ingredients.map((ingredient: any) => {
        return {
          value: ingredient.id,
          label: ingredient.name,
        };
      });
      const _ingredientsWithScalingUnit: Type_IngredientsWithScalingUnit[] = data.ingredients.map((ingredient: any) => {
        return {
          value: ingredient.id,
          label: ingredient.name,
          scalingUnit: ingredient.scaling_unit,
        };
      });
      setIngredientsWithScaling(_ingredientsWithScalingUnit)
      setIngredients(_ingredients);
    },
  });

  useQuery(GET_UNITS, {
    onCompleted: (data) => {
      const _units = data.units.map((unit: any) => {
        return {
          value: unit.id,
          label: unit.name,
        };
      });
      setUnits(_units);
    },
  });

  useQuery(GET_TAGS, {
    variables: {
      distinct_on: ["tags"],
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _tags = data.filter_tags.map((tag: any) => {
        return {
          label: tag.tags,
          value: tag.id,
        };
      });
      setTags(_tags);
    },
  });

  useQuery(GET_GROUP_TAGS, {
    onCompleted: (data) => {
      const _tags = data.group_tag.map((tag: any) => {
        return {
          label: tag.tags,
          value: tag.id,
        };
      });
      setTags([...tags, ..._tags]);
    },
  });

  useQuery(GET_PREPARATIONS, {
    variables: {
      distinct_on: ["name"],
    },
    onCompleted: (data) => {
      const _preparation = data.prepration.map((preparation: any) => {
        return {
          label: preparation.name,
          value: preparation.name,
        };
      });
      setPreparations(_preparation);
    },
  });

  useEffect(() => {
    if (location.pathname.split("/")[1] === "update-meal") {
      const id = parseInt(location.pathname.split("/")[2]);
      setMealId(id);
      setIsUpdatePage(true);
      setLoading(true);
    } else {
      setIsUpdatePage(false);
    }
  }, []);

  return (
    <PageTemplate>
      <div onClick={() => goBack()}>
        <GoBackButton />
      </div>
      <PageHeading heading={isUpdatePage ? "Update Dish" : "Create New Dish"} />

      <AddMealForm
        {...{
          cuisineType,
          allergies,
          dishes,
          ingredients,
          units,
          tags,
          preparations,
          selectedIngredientsObject,
          setSelectedIngredientsObject,
          isUpdatePage,
          mealId,
          loading,
          setLoading,
          imageLabel,
          setImageLabel,
          imageUrl,
          setImageUrl,
          formValues,
          setFormValues,
          setShowNotification,
          setNotification,
          columns,
          setColumns,
          ingredientsWithScaling
        }}
      />
      {showNotification && <Notification {...notification} showNotification={setShowNotification} />}
    </PageTemplate>
  );
};
