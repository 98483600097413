import React, { useContext } from "react";
import { SearchInput } from "../../atoms/search-input/search-input";
import { SearchIcon } from "../../atoms/search-icon/search-icon";
import { CloseButtonFilled } from "../../atoms/close-button-filled/close-button-filled";

type Props = {
  handleOnChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  onPressEnterInSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickEmptySearchButton: () => void;
  onClickSearchIcon: () => void
};

export const SearchInputWithIcon: React.FC<Props> = ({
  handleOnChangeSearch,
  searchValue,
  onPressEnterInSearch,
  onClickEmptySearchButton,
  onClickSearchIcon
}) => {
  return (
    <div className="relative w-full">
      <div onClick={onClickSearchIcon}>
        <SearchIcon className="text-[#d8d8d8] absolute top-[50%] mt-[-10px] left-[15.42px]" />
      </div>
      {searchValue && (
        <div className="absolute right-[15.42px] top-[50%] mt-[-8px]" onClick={onClickEmptySearchButton}>
          <CloseButtonFilled />
        </div>
      )}
      <SearchInput
        onChangeSearchValue={handleOnChangeSearch}
        onPressEnter={onPressEnterInSearch}
        searchValue={searchValue}
        placeholder="Search"
      />
    </div>
  );
};
