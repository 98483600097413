import React from "react";
import { EditPencilIcon } from "../../atoms/edit-pencil-icon/edit-pencil-icon";
import { TrashIcon } from "../../atoms/trash-icon/trash-icon";
import { ViewIcon } from "../../atoms/view-icon/view-icon";

type SingleIngredientControlsProps = {
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  id: number;
};

export const SingleIngredientControls: React.FC<SingleIngredientControlsProps> = ({ handleEdit, handleDelete, id }) => {
  return (
    <div className="flex gap-x-[33.5px] items-center">
      <div onClick={() => handleEdit(id)}>
        <ViewIcon />
      </div>
      <div onClick={() => handleEdit(id)}>
        <EditPencilIcon />
      </div>
      <div onClick={() => handleDelete(id)}>
        <TrashIcon />
      </div>
    </div>
  );
};
