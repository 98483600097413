import { useLazyQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { STYLES } from "../../../constants";
import { GET_INGREDIENT_BY_ID } from "../../../graphql/queries";
import { TODO, Type_IngredientsInitialValue, Type_SelectData } from "../../../typescript/types";
import { getNameFromAWSLink } from "../../../utils/getNameFromAWSLink";
import { replacePlusWithSpaces } from "../../../utils/replacePlusWithSpace";
import { DeleteRowIcon } from "../../atoms/delete-row-icon/delete-row-icon";
import { FormLabel } from "../../atoms/form-label/form-label";
import { UploadButton } from "../../molecules/upload-image-button/upload-image-button";
import { TagsList } from "../tags-list/tags-list";
import "./styles.css";

type AddIngredientFormProps = {
  allergies: Type_SelectData[];
  selectedAllergies: Type_SelectData[];
  isUpdatePage: boolean;
  handleSubmitIngredient: () => void;
  setSelectedAllergies: React.Dispatch<React.SetStateAction<Type_SelectData[]>>;
  goBack: () => void;
  setIsUpdatePageToTrue: () => void;
  _setIngredientId: (id: number) => void;
  setAllergiesToDelete: React.Dispatch<React.SetStateAction<number[]>>;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  imageLabel: string;
  imageUrl: string;
  setImageUrl: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setImageLabel: React.Dispatch<React.SetStateAction<string>>;
  formValues: Type_IngredientsInitialValue;
  setFormValues: React.Dispatch<React.SetStateAction<Type_IngredientsInitialValue>>;
  handleRemoveImage: () => void;
  selectedType: Type_SelectData;
  setselectedType: React.Dispatch<React.SetStateAction<Type_SelectData>>;
  ingredientTypes: Type_SelectData[];
  fileUploadRef: any;
};

export const AddIngredientForm: React.FC<AddIngredientFormProps> = ({
  allergies,
  selectedAllergies,
  isUpdatePage,
  handleSubmitIngredient,
  setSelectedAllergies,
  goBack,
  setIsUpdatePageToTrue,
  _setIngredientId,
  setAllergiesToDelete,
  handleImageUpload,
  imageLabel,
  imageUrl,
  setImageUrl,
  loading,
  setLoading,
  setImageLabel,
  formValues,
  setFormValues,
  handleRemoveImage,
  selectedType,
  setselectedType,
  ingredientTypes,
  fileUploadRef,
}) => {
  const location = useLocation();

  const [getIngredientDetailsById] = useLazyQuery(GET_INGREDIENT_BY_ID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormValues({
        ...formValues,
        scalingUnit: data.ingredients[0].scaling_unit,
        ingredientName: data.ingredients[0].name,
      });

      if (data.ingredients[0].image_url) {
        setImageUrl(data.ingredients[0].image_url);
        const _label: string = data.ingredients[0].image_url;
        const labelArray = getNameFromAWSLink(_label);
        const labelWithoutPluses = replacePlusWithSpaces(labelArray);
        setImageLabel(labelWithoutPluses);
      }

      const _allergies = data?.ingredients[0]?.ingredient_allergies.map((allergies: TODO) => {
        return {
          label: allergies.allergy.name,
          value: allergies.allergy.id,
        };
      });

      const _ingredientType: any = ingredientTypes.find((type) => type.value == data.ingredients[0].type_id);
      setselectedType(_ingredientType);
      const allergyIds = data?.ingredients[0]?.ingredient_allergies.map((allergies: TODO) => allergies.allergy.id);
      setAllergiesToDelete(allergyIds);
      setSelectedAllergies(_allergies);
    },
  });

  const onDeleteAllergies = (id: number) => {
    const filterdAllergies = selectedAllergies.filter((allergy) => parseInt(allergy.value) !== id);
    setSelectedAllergies(filterdAllergies);
  };

  useEffect(() => {
    if (location.pathname.split("/")[1] === "update-ingredient") {
      const id = parseInt(location.pathname.split("/")[2]);
      getIngredientDetailsById({
        variables: {
          where: {
            id: {
              _eq: id,
            },
          },
        },
      });
      setIsUpdatePageToTrue();
      _setIngredientId(id);
    }
  }, []);

  const handleFormValues: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) => {
    if (e.target.name === "ingredientName") {
      e.target.value.length <= 40 && setFormValues({ ...formValues, ingredientName: e.target.value });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="flex flex-col" style={{ maxWidth: 568, width: 568 }}>
      <FormLabel label="Ingredient Name*" />
      <div className="relative">
        <input
          type="text"
          placeholder="Ingredient Name*"
          className="input"
          value={formValues.ingredientName}
          name="ingredientName"
          onChange={handleFormValues}
        />
        <p className="input-text-length-indicator">{formValues.ingredientName.length}/40 </p>
      </div>

      <FormLabel label="Upload Image*" />
      <div className="flex gap-x-[20px] items-center">
        <UploadButton
          label={imageLabel || "Select Image"}
          handleImageUpload={handleImageUpload}
          fileUploadRef={fileUploadRef}
        />
        {imageUrl && (
          <div className="relative min-w-[120px] w-[120px]">
            <img src={imageUrl} alt="" className="w-[120px] h-[100px] rounded-md " />
            <div className="absolute top-[5px] right-[5px]" onClick={handleRemoveImage}>
              <DeleteRowIcon />
            </div>
          </div>
        )}
      </div>

      <FormLabel label="Type*" />
      <Select
        options={ingredientTypes}
        value={selectedType}
        onChange={(value: any) => setselectedType(value)}
        className="mb-[30px]"
        styles={STYLES}
        placeholder="Select..."
      />

      <FormLabel label="Scaling Unit*" />
      <input
        type="number"
        placeholder="Scaling Unit*"
        className="input"
        name="scalingUnit"
        value={formValues.scalingUnit}
        onChange={handleFormValues}
        min={0}
      />

      <FormLabel label="Allergens*" />
      <TagsList tags={selectedAllergies} onDeleteTag={onDeleteAllergies} />
      <Select
        options={allergies}
        value={selectedAllergies}
        onChange={(value: any) => setSelectedAllergies(value)}
        className="mb-[30px]"
        styles={STYLES}
        isMulti
        backspaceRemovesValue={false}
      />

      {/* <p>Brand Name</p>
      <input type="text" placeholder="Brand Name" {...register("BrandName", { maxLength: 40 })} /> */}

      {/* <p>Ingredient ID</p>
      <input type="number" placeholder="Ingredient ID" {...register("IngredientID")} /> */}

      {/* <p>Compatible Diet</p>
      <MultiSelect
        options={Options}
        labelledBy="CompatibleDiet*"
        value={compatibleDiet}
        onChange={setCompatibleDiet}
        disableSearch
        className="select"
      /> */}

      {/* <p>Ingredient Unit Scaling Formula</p>
      <input
        type="text"
        placeholder="Ingredient Unit Scaling Formula*"
        {...register("IngredientUnitScalingFormula", { required: true })}
      /> */}

      <div className="flex items-center gap-x-[30px]">
        <button className="submit-button" onClick={handleSubmitIngredient} disabled={loading}>
          {isUpdatePage ? "Update Ingredient" : "Save Ingredient"}
        </button>
        <p
          className="flex items-center text-gray3 text-[12px] leading-[15px] font-medium mt-[7px] cursor-pointer hover:text-primary"
          onClick={() => goBack()}
        >
          Cancel
        </p>
      </div>
    </div>
  );
};
