import { gql } from "@apollo/client";

export const GET_ALL_INGREDIENTS = gql`
  query ingredients($limit: Int, $offset: Int, $order: [ingredients_order_by!]) {
    ingredients(limit: $limit, offset: $offset, order_by: $order) {
      name
      id
      scaling_unit
      type_id
      ingredient_allergies {
        allergy {
          name
          id
        }
      }
    }
  }
`;

export const GET_ALL_INGREDIENTS_TOTAL = gql`
  query {
    ingredients_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_ALLERGIES = gql`
  query allergies {
    allergies {
      name
      id
    }
  }
`;

export const GET_INGREDIENT_BY_ID = gql`
  query ingredients($where: ingredients_bool_exp) {
    ingredients(where: $where) {
      name
      id
      image_url
      scaling_unit
      type_id
      ingredient_allergies {
        allergy {
          name
          id
        }
      }
    }
  }
`;

export const GET_ALL_MEALS = gql`
  query meal($limit: Int, $offset: Int) {
    meal(limit: $limit, offset: $offset, order_by: { name: asc }) {
      name
      cuisine
      image_url
      id
    }
  }
`;

export const GET_ALL_MEALS_TOTAL = gql`
  query {
    meal_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ALL_CUISINE_TYPES = gql`
  query filter_tags($where: filter_tags_bool_exp, $distinct_on: [filter_tags_select_column!]) {
    filter_tags(where: $where, distinct_on: $distinct_on) {
      id
      tags
    }
  }
`;
export const GET_UNITS = gql`
  query getUnits {
    units {
      id
      name
    }
  }
`;

export const GET_TAGS = gql`
  query filter_tags($distinct_on: [filter_tags_select_column!]) {
    filter_tags(distinct_on: $distinct_on) {
      id
      tags
    }
  }
`;

export const GET_GROUP_TAGS = gql`
  query {
    group_tag {
      id
      tag
    }
  }
`;

export const GET_PREPARATIONS = gql`
  query prepration($distinct_on: [prepration_select_column!]) {
    prepration(distinct_on: $distinct_on) {
      name
    }
  }
`;

export const GET_MEAL_DETAILS_BY_ID = gql`
  query meal($where: meal_bool_exp) {
    meal(where: $where) {
      name
      description
      cuisine
      image_url
      cook_time
      meal_filters {
        id
        filter_tag {
          tags
          id
        }
      }
      submeal_meals {
        id
        mealBySubMealId {
          id
          name
        }
      }
      #ingredients
      recipes {
        id
        ingredient {
          id
          name
        }
        quantity
        unit {
          id
          name
        }
        add_on_pax {
          base_ratio_multiplier
          id
        }
        preparation
      }
      serving
      prep_time
      shelf_time {
        id
        time
      }
      video_url
      instructions {
        id
        description
        name
      }
    }
  }
`;

export const SEARCH_MEALS = gql`
  query meal($where: meal_bool_exp, $limit: Int, $offset: Int) {
    meal(where: $where, limit: $limit, offset: $offset) {
      name
      cuisine
      image_url
      id
    }
  }
`;

export const SEARCH_INGREDIENTS = gql`
  query ingredients($where: ingredients_bool_exp, $limit: Int, $offset: Int) {
    ingredients(where: $where, limit: $limit, offset: $offset) {
      name
      id
      scaling_unit
      type_id
      ingredient_allergies {
        allergy {
          name
          id
        }
      }
    }
  }
`;

export const GET_INGREDIENT_TYPE = gql`
  query {
    ingredient_type {
      id
      name
    }
  }
`;
