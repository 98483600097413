import React from "react";

export const UploadButton: React.FC<{
  label: string;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fileUploadRef: React.LegacyRef<HTMLInputElement> | undefined
}> = ({ label, handleImageUpload, fileUploadRef }) => {
  return (
    <div
      className="px-[20px] py-[12px] text-primary text-[12px] leading-[16px] text-center rounded-[30px] border-[2px] border-primary mb-[30px] cursor-pointer w-[123px] min-w-[123px]"
      style={{ width: "fit-content" }}
    >
      <label htmlFor={`upload-ingredient-image`} className="text-primary cursor-pointer">
        Upload Image
      </label>
      <input
        id={`upload-ingredient-image`}
        accept="image/*"
        // onChange={handleImage}
        type="file"
        className="hidden"
        onChange={handleImageUpload}
        ref={fileUploadRef}
      ></input>
    </div>
  );
};
