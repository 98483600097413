import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Loader } from "../../components/molecules/loader/loader";
import { Notification } from "../../components/molecules/notification/notification";
import { IngredientsList } from "../../components/organisms/ingredients-list/ingredients-list";
import { ListHeader } from "../../components/organisms/list-header/list-header";
import { PageTemplate } from "../../components/templates/page-template/page-template";
import { DELETE_INGREDIENT_BY_ID } from "../../graphql/mutations";
import {
  GET_ALL_INGREDIENTS,
  GET_ALL_INGREDIENTS_TOTAL,
  GET_INGREDIENT_TYPE,
  SEARCH_INGREDIENTS,
} from "../../graphql/queries";
import { Types_Ingredients, Type_IngredientType, Type_Notification } from "../../typescript/types";

export const Ingredients: React.FC = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");
  const [ingredients, setIngredients] = useState<Types_Ingredients[]>([]);
  const [totalIngredients, setTotalIngredients] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [justDeletedFlag, setJustDeletedFlag] = useState(false);
  const [justSearchedFlag, setJustSearchedFlag] = useState(false);
  const [ingredientTypes, setIngredientTypes] = useState<Type_IngredientType[]>([]);
  const [notification, setNotification] = useState<Type_Notification>({
    heading: "",
    type: "neutral",
    subHeading: "",
  });
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const LIMIT = 30;
  const OFFSET = 30;

  const [getAllIngredients] = useLazyQuery(GET_ALL_INGREDIENTS, {
    variables: {
      limit: LIMIT,
      offset: 0,
      order: { name: "asc" },
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _ingredients = ingredients;
      const concatIngredients = _ingredients.concat(data.ingredients);
      setIngredients(concatIngredients);
      setLoading(false);
    },
  });

  const [getIngredientTypes] = useLazyQuery(GET_INGREDIENT_TYPE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data) return;

      const _ingredientTypes = data?.ingredient_type?.map((type: Type_IngredientType) => {
        return {
          name: type.name,
          id: type.id,
        };
      });
      setIngredientTypes(_ingredientTypes);
    },
    onError: (error) => {
      if (!error) return;

      setNotification({
        heading: "An Error Occured!",
        type: "error",
      });
    },
  });

  const [searchForIngredients] = useLazyQuery(SEARCH_INGREDIENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const _ingredients = ingredients;
      const concatIngredients = _ingredients.concat(data.ingredients);
      setIngredients(concatIngredients);
      setLoading(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    setIngredients([]);
    setOffset(0);
    getTotal();
    getIngredientTypes();
    getAllIngredients({
      variables: {
        order: { name: "asc" },
        limit: LIMIT,
        offset: 0,
      },
    });
  }, []);

  const [getTotal] = useLazyQuery(GET_ALL_INGREDIENTS_TOTAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setTotalIngredients(data.ingredients_aggregate.aggregate.count);
    },
  });

  const [deleteIngredient] = useMutation(DELETE_INGREDIENT_BY_ID);

  const handleAddToList = () => history.push(`/create-new-ingredient`);

  const handleOnChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void = (e) =>
    setSearchValue(e.target.value);

  const handleEdit: (id: number) => void = (id) => history.push(`/update-ingredient/${id}`);

  const pagination: () => void = () => {
    // flag so that the function does not update value of
    // offset when an ingredient is deleted.
    // set to True after 2 sec of deletion
    if (!justDeletedFlag) {
      const _offset = offset + OFFSET;
      setOffset(_offset);
      getAllIngredients({
        variables: {
          order: { name: "asc" },
          limit: LIMIT,
          offset: _offset,
        },
      });
    }
  };

  const handleDelete: (id: number) => void = async (id) => {
    try {
      setIsNotificationVisible(true);
      setNotification({
        heading: "Deleting...",
        type: "warning",
      });
      await deleteIngredient({
        variables: {
          where: {
            id: {
              _eq: id,
            },
          },
        },
      });

      setJustDeletedFlag(true);
      setIngredients([]);
      setOffset(0);
      search()
      setIsNotificationVisible(true);
      setNotification({
        heading: "Deleted!",
        type: "success",
      });
      // flag so that the function does not update value of
      // offset when an ingredient is deleted.
      // set to True after 2 sec of deletion
      setTimeout(() => {
        setJustDeletedFlag(false);
      }, 2000);
    } catch (error) {
      setIsNotificationVisible(true);
      setNotification({
        heading: "Unable to delete!",
        subHeading: "Ingredient is being used in a meal",
        type: "error",
      });
    }
  };

  const search = () => {
    setOffset(0);
    setJustSearchedFlag(true);
    setIngredients([]);
    setLoading(true);
    searchForIngredients({
      variables: {
        where: {
          name: {
            _ilike: `%${searchValue.trim()}%`,
          },
        },
      },
    });
  };

  const onPressEnterInSearch: (event: React.KeyboardEvent<HTMLInputElement>) => void = (e) =>
    e.key === "Enter" && search();

  const onClickEmptySearchButton = () => {
    if (!searchValue) return;

    setIngredients([]);
    setJustSearchedFlag(false);
    setLoading(true);
    getTotal();
    getAllIngredients({
      variables: {
        order: { name: "asc" },
        limit: 30,
        offset: 0,
      },
    });
    setSearchValue("");
  };

  const onClickSearchIcon = () => {
    search();
  };

  return (
    <PageTemplate>
      <ListHeader
        {...{
          handleAddToList,
          handleOnChangeSearch,
          searchValue,
          onPressEnterInSearch,
          onClickEmptySearchButton,
          onClickSearchIcon,
        }}
        pageHeading={`Ingredients (${totalIngredients})`}
      />
      {!loading ? (
        <IngredientsList
          data={ingredients}
          {...{ handleDelete, handleEdit, totalIngredients, pagination, justSearchedFlag, ingredientTypes }}
        />
      ) : (
        <Loader />
      )}
      {isNotificationVisible && <Notification {...notification} showNotification={setIsNotificationVisible} />}
    </PageTemplate>
  );
};
