import { TODO } from "./typescript/types";

export const STYLES = {
  control: (styles: TODO, { isFocused, isSelected }: TODO) => ({
    ...styles,
    backgroundColor: "white",
    minHeight: 50,
  }),
  option: (styles: TODO, { data, isDisabled, isFocused, isSelected }: TODO) => {
    return {
      ...styles,
      "fontSize": 14,
      "backgroundColor": isDisabled ? null : isSelected ? "rgb(230,230,230)" : isFocused ? "rgb(247,247,247)" : null,
      "color": isDisabled ? "#ccc" : isSelected && "black",
      "cursor": isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && isSelected && "rgb(247,247,247)",
      },
    };
  },
  multiValue: (styles: TODO, { data }: TODO) => {
    return {
      ...styles,
      display: "none",
    };
  },
  multiValueLabel: (styles: TODO, { data }: TODO) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles: TODO, { data }: TODO) => ({
    ...styles,
    "color": data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
  input: (styles: TODO, { data, isDisabled, isFocused, isSelected }: TODO) => {
    return {
      ...styles,
      fontSize: 14,
    };
  },
  clearIndicator: (styles: TODO) => {
    return {
      ...styles,
      display: "none",
    };
  },
  placeholder: (styles: TODO) => ({ ...styles, fontSize: 14 }),
  singleValue: (styles: TODO, { data }: TODO) => ({ ...styles, fontSize: 14 }),
  valueContainer: (styles: TODO) => ({ ...styles, paddingLeft: 17 }),
};

export const BASE_URL_S3_BUCKET_RESPONSE = "https://menulyuploads.s3.amazonaws.com";

export const INGREDIENT_TYPE = [
  {
    label: "Dry Ingredient",
    value: 0,
  },
  {
    label: "Liquid",
    value: 1,
  },
  {
    label: "Liquid Solid",
    value: 2,
  },
  {
    label: "Granular",
    value: 3,
  },
];

export const VALID_IMAGE_TYPES = ["jpeg", "jpg", "png"]